<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_partner_doctor") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.phone_number.title" prop="phone_number">
            <crm-input
              v-model="form.phone_number"
              :maskFormat="'tel'"
              autocomplete="off"
              size="medium"
            ></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item
            :label="columns.partner_clinic_id.title"
            prop="partner_clinic"
          >
            <el-select
              :placeholder="$t('message.class')"
              v-model="form.partner_clinic_ids"
              :multiple="true"
              clearable
              filterable
            >
              <el-option
                v-for="item in clinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                size="medium"
              >
              </el-option>
            </el-select>

            <!-- <el-input
              v-model="form.partner_clinic_id"
              autocomplete="off"
            ></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
      <div class="block">
        <el-tree
          class="filter-tree"
          :data="data"
          show-checkbox
          node-key="id"
          :filter-node-method="filterNode"
          ref="items"
          @node-click="handleNodeClick"
          :expand-on-click-node="true"
        >
          <span
            :style="`background: ${data.color}`"
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <span class="aaa  my-tree-khan">
              <div>{{ data.name }}</div>
              <el-select
                :placeholder="$t('message.class')"
                v-model="data.type_raise_amount"
                :disabled="!node.checked"
                size="small"
                class="my-kh ml-3"
                style=""
              >
                <el-option
                  v-for="item in classes"
                  :key="item.name"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
              <crm-input
                :disabled="!data.type_raise_amount"
                v-model="data.amount"
                :placeholder="'sum'"
                ref="'data_amount'+ node.id"
                class="my-kh"
                size="small"
              ></crm-input>
            </span>
          </span>
        </el-tree>
      </div>
      <!-- end col -->
      <!-- end row -->
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child-new";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawerChild],
  data() {
    return {
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      data: [],
      services: [],
    };
  },
  computed: {
    ...mapGetters({
      rules: "partnerDoctor/rules",
      model: "partnerDoctor/model",
      clinics: "partnerClinic/inventory_all",
      columns: "partnerDoctor/columns",
    }),
  },
  methods: {
    ...mapActions({
      servicesRealtionList: "services/relationList",
      loadClinics: "partnerClinic/inventoryAll",
      save: "partnerDoctor/store",
    }),
    afterOpened() {
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.data = res.data.services;
        })
        .catch((err) => {});

      if (this.clinics && this.clinics.length == 0) {
        this.loadClinics();
      }
      // this.form = []; 
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    afterClosed() {
      this.resetForm("form");
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.$refs.items.getCheckedNodes();
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.resetForm("form");
              this.$refs.items.setCheckedNodes([]);
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
      // this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style>
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 95%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
</style>